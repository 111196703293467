<template>
    <slot/>
</template>

<script setup lang="ts">
    useHead({
            title: `Halaman Tidak Ditemukan - Enervon`,
            meta: [
                { name: 'robots', content:  `noindex` },
                { name: 'googlebot', content:  `noindex` },
                { name: 'description', content:  `Halaman Tidak Ditemukan - Enervon` },
                { name: 'og:title', content: `Halaman Tidak Ditemukan - Enervon` },
                { name: 'og:description', content: `Halaman Tidak Ditemukan - Enervon` },
            ]
    })
</script>
